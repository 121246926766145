<svelte:options tag="appkit-studio" />
<script>
	import { onMount } from "svelte";
	
	onMount(() => {
		console.log("Studio loaded");
	});
	
</script>

<style>
	:host {
		all: initial;
		padding: 50px;
	}
</style>

<h1>Appkit</h1>